<template>
  <v-card v-if="item" flat>
    <v-card-subtitle class="py-0">
      <v-chip x-small color="primary" class="mr-1">{{ item.priority }}</v-chip>
    </v-card-subtitle>

    <v-card-title class="pt-1 justify-space-between flex-nowrap">
      <span>{{ item.subject }}</span>
      <v-btn
        outlined
        small
        color="primary lighten-3"
        class="ml-4 px-2"
        :to="{ name: `tickets-item`, params: { id: item.id } }"
      >
        <v-icon small left>mdi-pencil</v-icon>
        Bearbeiten
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="align-center d-flex">
      {{ item.type }}
    </v-card-subtitle>
    <!--
        <v-card-text class="pt-4">
          <pre>{{ item }}</pre>
        </v-card-text> -->

    <v-card-text>
      <p v-if="item.user">
        <b>Bearbeiter:</b>
        {{ item.user.firstname }} {{ item.user.lastname }}
      </p>
      <template v-if="!pendingSelectedItemTicket && ticket">
        <template v-if="ticket.message">
          <h3>Message</h3>
          <p>{{ ticket.message }}</p>
        </template>

        <template v-if="ticket.ticket_tasks.length >= 1">
          <h3>Ticket Tasks</h3>
          <p v-for="task in ticket.ticket_tasks" :key="task.id">{{ task.message }}</p>
        </template>
      </template>

      <template v-if="pendingSelectedItemTicket">
        <v-progress-circular indeterminate />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import api from '@/api'

export default defineComponent({
  props: {
    item: {
      type: Object,
    },
  },
  setup(props) {
    const pendingSelectedItemTicket = ref(false)
    const ticket = ref(null)
    const controller = ref(new AbortController())

    const fetchTicket = async ticketId => {
      pendingSelectedItemTicket.value = true

      if (pendingSelectedItemTicket.value) {
        controller.value.abort()
        controller.value = new AbortController()
      }

      try {
        const { data } = await api.item.getEditItem('tickets', ticketId, controller.value)
        ticket.value = data.ticket
      } catch (error) {
        return false
      }

      pendingSelectedItemTicket.value = false
    }

    watch(
      _ => props?.item,
      async _ => {
        if (props.item !== null) {
          ticket.value = null
          fetchTicket(props.item.id)
        }
      },
    )

    if (props.item) {
      fetchTicket(props.item.id)
    }

    return {
      pendingSelectedItemTicket,
      ticket,
    }
  },
})
</script>
