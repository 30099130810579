<template>
  <OverviewWrapper
    :value="selectedItem"
    :sidebar-items="[
      {
        title: 'Übersicht',
        icon: 'mdi-info',
        name: 'info',
        active: value => {
          return value && value.integration;
        },
      },
    ]"
    @input="onInput"
  >
    <v-row align="stretch">
      <v-col cols="12" md="6" lg="3">
        <AppCard class="h-100" :title="$t('Meine aktiven Tickets')" title-actions>
          <template #titleActions>
            <v-avatar color="secondary">
              <v-icon dark>mdi-ticket</v-icon>
            </v-avatar>
          </template>

          <p class="text-h4">{{ ticketStatistics.my_tickets }}</p>
        </AppCard>
      </v-col>

      <v-col cols="12" md="6" lg="3">
        <AppCard class="h-100" :title="$t('Meine erstellten Tickets')" title-actions>
          <template #titleActions>
            <v-avatar color="secondary">
              <v-icon dark>mdi-plus</v-icon>
            </v-avatar>
          </template>

          <p class="text-h4">{{ ticketStatistics.my_tickets_created }}</p>
        </AppCard>
      </v-col>

      <v-col cols="12" md="6" lg="3">
        <AppCard class="h-100" :title="$t('Meine geschlossenen Tickets')" title-actions>
          <template #titleActions>
            <v-avatar color="secondary">
              <v-icon dark>mdi-close</v-icon>
            </v-avatar>
          </template>

          <p class="text-h4">{{ ticketStatistics.my_tickets_closed }}</p>
        </AppCard>
      </v-col>

      <v-col cols="12" md="6" lg="3">
        <AppCard class="h-100" :title="$t('Alle meine Tickets')" title-actions>
          <template #titleActions>
            <v-avatar color="secondary">
              <v-icon dark>mdi-archive</v-icon>
            </v-avatar>
          </template>

          <p class="text-h4">{{ ticketStatistics.ges_tickets }}</p>
        </AppCard>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <AppCard :title="$t('My Tickets')" title-actions>
          <template #titleActions>
            <div class="d-flex flex-column flex-md-row align-end align-md-center justify-end">
              <v-text-field
                v-model="search"
                class="mb-4 mb-md-0 mr-md-4"
                outlined
                hide-details="auto"
                dense
                type="text"
                label="Search Ticket"
                style="max-width: 200px"
                prepend-inner-icon="mdi-magnify"
              />
              <v-btn to="Tickets/create" color="secondary" rounded dark>
                <v-icon>mdi-plus</v-icon>
                <span class="d-none d-sm-inline">Ticket erstellen</span>
              </v-btn>
            </div>
          </template>

          <AppPagination v-if="pagination" :pagination="pagination" location="top" />

          <v-data-table
            class="v-data-table__overview"
            :headers="headers"
            :items="tickets"
            :page="pagination.page"
            :items-per-page="pagination.perPage"
            :loading="pending.getMyTickets"
            hide-default-footer
            :item-class="
              item => 'clickable' + (['closed', 'storno', 'canceled'].includes(item.status) ? highlightClasses : '')
            "
            @click:row="onClickRow"
          >
            <template #top>
              <div class="d-flex justify-end">
                <v-switch v-model="showClosedTickets" label="Show closed tickets" />
              </div>
            </template>

            <template #[`item.client`]="{ item }">
              <template v-if="item.handle">
                <b>{{ item.handle.organisation }}</b>
                {{ item.handle.firstname }}
                {{ item.handle.lastname }}
              </template>
            </template>
            <!-- <template #[`item.user`]="{ item }">
            <span v-if="item.user">
              {{ item.user.firstname }}
              {{ item.user.lastname }}
            </span>
          </template> -->

            <template #[`item.priority`]="{ item }">
              <v-chip x-small color="primary" class="mr-1">{{ item.priority }}</v-chip>
            </template>

            <template #[`item.duedate`]="{ item }">
              {{ item.duedate | moment('DD.MM.YYYY') }}
            </template>

            <template #[`item.actions`]="{ item }">
              <TicketRowActions :ticket="item" :pending-tickets="pendingTickets" />
            </template>
          </v-data-table>

          <AppPagination v-if="pagination" :pagination="pagination" />
        </AppCard>
      </v-col>
    </v-row>

    <template #[`sidebar.info`]="{ item }">
      <TicketSidebarInfo :item="item" />
    </template>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, reactive, ref, watch, computed, onBeforeUnmount } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import OverviewWrapper from '@/layouts/OverviewWrapper'
import TicketRowActions from '@/components/Tickets/TicketRowActions'
import TicketSidebarInfo from '@/components/Tickets/TicketSidebarInfo'

export default defineComponent({
  name: 'TicketBoardOverview',
  components: {
    AppCard,
    AppPagination,
    TicketRowActions,
    OverviewWrapper,
    TicketSidebarInfo,
  },
  setup(props, { root: { $store, $route, $vuetify }, root }) {
    const page = ref(1)
    const search = ref(null)
    const pendingTickets = reactive([])
    const headers = [
      {
        sortable: false,
        text: 'Ticket',
        value: 'ticket_unique_id',
      },
      {
        sortable: false,
        text: 'Client',
        value: 'client',
      },
      {
        sortable: false,
        text: 'Subject',
        value: 'subject',
      },
      {
        sortable: false,
        text: 'Priority',
        value: 'priority',
      },
      {
        sortable: false,
        text: 'Due date',
        value: 'duedate',
      },
      {
        sortable: false,
        text: 'Status',
        value: 'status',
      },
      // {
      //   sortable: false,
      //   text: 'User',
      //   value: 'user',
      // },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const selectedItem = ref(null)
    const selectedRow = ref(null)

    const activeQuery = computed(_ => root.$route.query)

    // store
    const tickets = computed(_ => $store.state.tickets.tickets)
    const ticketStatistics = computed(_ => $store.state.tickets.ticketStatistics)
    const pagination = computed(_ => $store.state.tickets.pagination)
    const pending = computed(_ => $store.state.tickets.pending)
    const highlightClasses = computed(_ => {
      return $vuetify.theme.dark ? 'grey darken-4 text--disabled' : 'grey lighten-4 text--disabled'
    })
    const getMyTickets = payload => $store.dispatch('tickets/getMyTickets', payload)
    const getTicketStatistics = _ => $store.dispatch('tickets/getTicketStatistics')
    const clearTickets = _ => $store.commit('tickets/CLEAR_TICKETS')

    page.value = $route.query.page || page.value
    getMyTickets({ page: page.value })
    getTicketStatistics()

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      clearTimeout(delayTimer)
      getTicketsWithParams()
    })

    const showClosedTickets = ref(false)
    watch(showClosedTickets, _ => {
      clearTimeout(delayTimer)
      getTicketsWithParams()
    })

    const delayTimer = ref(null)
    watch(search, _ => {
      clearTimeout(delayTimer.value)
      delayTimer.value = setTimeout(() => {
        page.value = 1
        getTicketsWithParams()
      }, 500)
    })

    const getTicketsWithParams = _ => {
      const params = {
        page: page.value,
      }

      if (showClosedTickets.value) {
        params.show_closed = showClosedTickets.value
      }

      if (search.value) {
        params.search = search.value
      }

      getMyTickets(params)
    }

    const onClickRow = (item, row) => {
      if (selectedRow.value) {
        selectedRow.value.select(false)
        selectedRow.value = null
      }

      if (item === selectedItem.value) {
        selectedItem.value = null
      } else {
        selectedItem.value = item
        selectedRow.value = row
        selectedRow.value.select()
      }
    }

    const onInput = value => {
      if (!value) {
        selectedItem.value = null
      }
      if (selectedRow.value) {
        selectedRow.value.select(false)
        selectedRow.value = null
      }
    }

    onBeforeUnmount(_ => clearTickets())

    return {
      page,
      search,
      pendingTickets,
      headers,
      tickets,
      ticketStatistics,
      pagination,
      pending,
      highlightClasses,
      showClosedTickets,
      selectedItem,
      onClickRow,
      onInput,
    }
  },
}) //
</script>


<style lang="scss" scoped>
.table-responsive {
  overflow: visible;
}
</style>
